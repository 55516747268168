.button {
  border-radius: 24px;
  width: 100%;
  padding: 0.9rem 0.625rem;
  text-align: center;
  font-weight: 600;
  color: #ffffff;
  outline: none;
  border: none;
  user-select: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    transform: scale(0.99);
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }

  & > span {
    margin-left: 13.71px;
  }

  &--primary {
    background: #f20788;

    &:disabled {
      background: hsla(
        hue(#f20788),
        saturation(#f20788),
        lightness(#f20788),
        0.7
      );
    }
  }

  &--secondary {
    color: #f20788;
    background: #fff0f7;

    &:disabled {
      color: #737c8d;
      background: #d9d9d9;
    }
  }

  &--outline {
    color: #f20788;
    background: #ffffff;
    border: 1px solid #f20788;

    &:disabled {
      color: #737c8d;
      border: 1px solid #737c8d;
    }
  }
}
