.errorBoundary {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100vh;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;
    justify-content: center;
    align-items: center;
  }

  &__image {
    margin: 0 0 13px 0;
  }

  &__title {
    font-weight: 700;
    letter-spacing: -0.02em;
    font-size: 24px;
    margin: 0;

    &--main {
      margin: 0 0 28px 0;
    }
  }

  &__subtext {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.8px;
    color: #5b667a;
    margin: 12px 0 16px 0;
  }

  &__button {
    width: 60% !important;
    border: 0.7px solid #a1a7b3 !important;
    background-color: #fff !important;
    color: #0d1627 !important;
    padding: 8px !important;
    font-size: 12px;
  }

  @media only screen and (min-width: 600px) {
    &__button {
      width: 40% !important;
    }
  }

  @media only screen and (min-width: 768px) {
    &__content {
      width: 403px;
    }

    &__title {
      font-size: 32px;
    }

    &__subtext {
      font-size: 16px;
      line-height: 19.2px;
      letter-spacing: -0.02em;
      margin-bottom: 24px;
    }
  }

  @media only screen and (min-width: 900px) {
    &__content {
      width: 450px;
    }

    &__button {
      width: 50% !important;
    }
  }
}
