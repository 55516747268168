@import 'https://fonts.cdnfonts.com/css/br-firma';

.App {
  height: 100vh;
  font-family: 'BR Firma', sans-serif !important;
  scrollbar-color: #fed7ed none;
  scrollbar-width: thin;

  margin: auto;
  max-width: 1600px;

  // Scrollbar design below
  /* Scrollbar width */
  ::-webkit-scrollbar,
  scrollbar-width {
    width: 4px;
    height: 4px;
  }
  /* Scrollbar Track */
  ::-webkit-scrollbar-track {
    background: none;
    border-radius: 10px;
  }
  scrollbar-track {
    background: none;
    border-radius: 10px;
  }
  /* Scrollbar Handle */
  ::-webkit-scrollbar-thumb,
  scrollbar-thumb {
    background: #fed7ed;
    border-radius: 10px;
    background-clip: padding-box;
  }
  /* Scrollbar Handle on hover */
  ::-webkit-scrollbar-thumb:hover,
  scrollbar-thumb:hover {
    background: #f20788;
  }
}

.react-toast-notifications__container,
.css-nvf14r-ToastContainer {
  z-index: 900000000000000 !important;
}
