.loader {
  position: relative;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &__spinner {
    position: absolute;
    animation: rotate 2s linear infinite;
    width: 70px;
    height: 70px;
  }

  &__logo {
    position: absolute;
    width: 40px;
    height: 40px;
  }
}
